import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const WochenbettPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Wochenbett</title>
        <meta
          name="description"
          content="Geburtsvorbereitende Akupunktur kann sehr gut auf den Verlauf der bevorstehenden Geburt einwirken"
        />
        <meta property="og:title" content="Wochenbett" />
        <meta
          property="og:description"
          content="Geburtsvorbereitende Akupunktur kann sehr gut auf den Verlauf der bevorstehenden Geburt einwirken"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Wochenbett</h1>
        <SingleImage image={props.data.wochenbett.fluid} />
        <p>
          Das Wochenbett ist eine sehr herausfordernde Zeit für die Frau.
          Hormonelle Schwankungen, körperliche Beschwerden nach der Geburt und
          die neue Herausforderung als Mutter stürzen die Frau oft in eine Berg
          und Talfahrt voller Emotionen.
        </p>
        <p>
          Abhängig vom Geburtsverlauf hat die Frau sehr viel Blut und Energie
          verloren. Zusammen mit der erschöpfenden Zeit der letzten
          Schwangerschaftswochen, kann dies ein Grund für den{" "}
          <strong>Mangel an Muttermilch</strong> sein. Eine nährstoffreiche
          Ernährung und Akupunktur, sowie chinesische Kräuter können die
          Milchbildung anregen.
        </p>
        <p>
          Wenn der Wochenfluss (Lochien) nicht kontinuierlich die verbleibenden
          Reste der Schwangerschaft aus der Gebärmutter transportieren kann,
          kann es zu einem <strong>Lochienstau</strong> kommen. Besteht dieser
          Stau über mehrere Tage besteht die Gefahr einer Infektion, da das
          verbliebene Gewebe ein guter Nährboden für Bakterien darstellt. Neben
          Hausmitteln wie öfter Stillen, Rückbildungstee und Bauchlage kann mit
          Akupunktur und chinesischen Kräutern der Fluss wieder angeregt werden.
        </p>
        <p>
          Durch den relativen Energie- und Blutmangel kann es zu
          unterschiedlichen Beschwerden kommen. Werden die Muskeln und Sehnen
          nicht ausreichend mit Blut versorgt kommt es zu{" "}
          <strong>Muskel- und Gelenkschmerzen</strong>, bei Herz Blut Mangel
          durch die erschöpfende neue Situation kann es zu{" "}
          <strong>Einschlafstörungen</strong>
          kommen. Der Energiemangel führt zu vermehrtem{" "}
          <strong>Schwitzen und Nachtschweiß</strong>. Akupunktur kann die
          Blockaden in diesen Fällen wieder lösen. In Kombination mit
          chinesischen Kräutern wird Blut, Yin und Qi wieder aufgebaut und die
          Beschwerden werden verschwinden.
        </p>
        <p>
          Nach einem <strong>Kaiserschnitt</strong> kann es zu{" "}
          <strong>Schmerzen und Missempfindungen</strong> im Bereich der Narbe,
          aber auch im Rücken und in den Beinen kommen. Durch Akupunktur kann
          der gestörte Energiefluss wieder hergestellt werden.
        </p>

        <div className="cta">
          <p>
            Buchen Sie jetzt Ihren 1. Termin! Sie können Ihr Baby auch gern
            mitbringen.
          </p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default WochenbettPage;

export const query = graphql`
  query WochenbettImageQuery {
    wochenbett: imageSharp(fluid: { originalName: { regex: "/wochenbett/" } }) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
